module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/public/i18n","languages":["en-ca","en-us","en-gb","en-au","en-eu"],"defaultLanguage":"en-ca","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P8QVD9V","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"147853330770663"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"shift-connect","accessToken":"MC5Ya2JqTFJFQUFDVUFPeVZV.77-9BhPvv71VDu-_ve-_ve-_vRrvv73vv70s77-9bGLvv711Du-_vT4T77-9NTksRe-_vWUC77-977-9","path":"/preview","previews":true},
    }]
