// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alberta-index-js": () => import("./../src/pages/alberta/index.js" /* webpackChunkName: "component---src-pages-alberta-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-business-index-js": () => import("./../src/pages/business/index.js" /* webpackChunkName: "component---src-pages-business-index-js" */),
  "component---src-pages-compliance-index-js": () => import("./../src/pages/compliance/index.js" /* webpackChunkName: "component---src-pages-compliance-index-js" */),
  "component---src-pages-contact-success-index-js": () => import("./../src/pages/contact-success/index.js" /* webpackChunkName: "component---src-pages-contact-success-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-download-complete-index-js": () => import("./../src/pages/download-complete/index.js" /* webpackChunkName: "component---src-pages-download-complete-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintenance-index-js": () => import("./../src/pages/maintenance/index.js" /* webpackChunkName: "component---src-pages-maintenance-index-js" */),
  "component---src-pages-oanda-contact-index-js": () => import("./../src/pages/oanda-contact/index.js" /* webpackChunkName: "component---src-pages-oanda-contact-index-js" */),
  "component---src-pages-oanda-thank-you-index-js": () => import("./../src/pages/oanda-thank-you/index.js" /* webpackChunkName: "component---src-pages-oanda-thank-you-index-js" */),
  "component---src-pages-oanda-index-js": () => import("./../src/pages/oanda/index.js" /* webpackChunkName: "component---src-pages-oanda-index-js" */),
  "component---src-pages-partnerships-index-js": () => import("./../src/pages/partnerships/index.js" /* webpackChunkName: "component---src-pages-partnerships-index-js" */),
  "component---src-pages-personal-index-js": () => import("./../src/pages/personal/index.js" /* webpackChunkName: "component---src-pages-personal-index-js" */),
  "component---src-pages-risk-management-white-paper-index-js": () => import("./../src/pages/risk-management-white-paper/index.js" /* webpackChunkName: "component---src-pages-risk-management-white-paper-index-js" */),
  "component---src-pages-secure-upload-index-js": () => import("./../src/pages/secure-upload/index.js" /* webpackChunkName: "component---src-pages-secure-upload-index-js" */),
  "component---src-pages-security-index-js": () => import("./../src/pages/security/index.js" /* webpackChunkName: "component---src-pages-security-index-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-snowbirds-contact-index-js": () => import("./../src/pages/snowbirds-contact/index.js" /* webpackChunkName: "component---src-pages-snowbirds-contact-index-js" */),
  "component---src-pages-snowbirds-index-js": () => import("./../src/pages/snowbirds/index.js" /* webpackChunkName: "component---src-pages-snowbirds-index-js" */),
  "component---src-pages-terms-privacy-index-js": () => import("./../src/pages/terms-privacy/index.js" /* webpackChunkName: "component---src-pages-terms-privacy-index-js" */),
  "component---src-pages-tuition-index-js": () => import("./../src/pages/tuition/index.js" /* webpackChunkName: "component---src-pages-tuition-index-js" */),
  "component---src-pages-upload-success-index-js": () => import("./../src/pages/upload-success/index.js" /* webpackChunkName: "component---src-pages-upload-success-index-js" */),
  "component---src-pages-verification-complete-index-js": () => import("./../src/pages/verification-complete/index.js" /* webpackChunkName: "component---src-pages-verification-complete-index-js" */),
  "component---src-pages-verification-step-2-index-js": () => import("./../src/pages/verification-step-2/index.js" /* webpackChunkName: "component---src-pages-verification-step-2-index-js" */),
  "component---src-pages-verification-index-js": () => import("./../src/pages/verification/index.js" /* webpackChunkName: "component---src-pages-verification-index-js" */),
  "component---src-pages-whistleblower-index-js": () => import("./../src/pages/whistleblower/index.js" /* webpackChunkName: "component---src-pages-whistleblower-index-js" */)
}

